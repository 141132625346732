
import SellCarForm from '@/components/modules/SellCarForm';
export default {
    components: {
        SellCarForm
    },
    props: {
        bgImage: {
            type: String,
            default: ''
        }
    }

}
