
import SellMyCarHeroArea from '@/components/modules/SellMyCarHeroArea'
import Motorway from '@/components/modules/Motorway'
export default {
    scrollToTop: true,
    components: {
        SellMyCarHeroArea,
        Motorway
    },
    data() {
        return {
            pageTitle: null,
            slugTitle: null,
            townName: null,
            motData: null,
            tagsData: null,
            search: null,
            loading: true
        };
    },
    head() {
        return {
            title: `Sell My Car ${this.pageTitle} | Sell My Car Online | Sell My Car with CheckMot.com`,
            meta: [
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: `Sell My Car ${this.pageTitle} | Sell My Car Online | Sell My Car with CheckMot.com`,
                },
                {
                    hid: 'twitter:title',
                    property: 'twitter:title',
                    content: `Sell My Car ${this.pageTitle} | Sell My Car Online | Sell My Car with CheckMot.com`,
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: this.pageDesc()
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: this.pageDesc()
                },
                {
                    hid: 'twitter:description',
                    property: 'twitter:description',
                    content: this.pageDesc()
                }
            ],
        }
    },
    async validate({ params, store }) {
        // await operations
        if (params.sellcar != (null || "")) {
            this.slugTitle = params.sellcar;
            return true; // if the params are valid
        }
        else {
            return false; // will stop Nuxt.js to render the route and display the error page
        }
    },
    created() {
        this.slugTitle = this.$route.params.sellcar;
        if (this.slugTitle && !this.slugTitle.startsWith("sell-my-car-"))
            this.$nuxt.error({ statusCode: 404 })
    },
    async fetch() {
        // Get station data
        this.townName = this.slugTitle ? this.slugTitle.split('car-').pop() : '';
        this.motData = await fetch(this.$config.fetchUrl + "/api/sellTownList/getTown/" + this.townName).then(res => res.json()).catch(error => {
            console.error("There was an error!", error);
        });
        if (this.motData && this.motData.city)
            this.pageTitle = this.motData.city

        this.loading = false;
        this.tagsData = await fetch(this.$config.fetchUrl + "/api/sellTownList/getAllTowns").then(res => res.json()).catch(error => {
            console.error("There was an error!", error);
        });
    },
    computed: {
        // Used for search function on the page
        filteredTagList() {
            if (this.tagsData && this.search) {
                return this.tagsData.filter(tag => {
                    return (tag.city.toLowerCase().includes(this.search.toLowerCase().trim()))
                })
            } else {
                return this.tagsData
            }
        },
    },
    methods: {
        scrollToForm() {
            window.scrollTo(0, 0);
        },
        pageDesc() {
            let defaultDesc = "Check MOT and Tax of UK vehicle, check when your mot is due and the history of your car by using the Check Mot search function. Check MOT online."
            if (this.motData && (this.motData.sellParaOne || this.motData.sellParaTwo || this.motData.sellParaThree)) {
                let pgData = this.motData.sellParaOne ? this.motData.sellParaOne : (this.motData.sellParaTwo ? this.motData.sellParaTwo : (this.motData.sellParaThree ? this.motData.sellParaThree : defaultDesc))
                pgData = pgData.replace(/(?:\r\n|\r|\n)/g, ' ').substring(0, 150);
                pgData = pgData.substr(0, Math.min(pgData.length, pgData.lastIndexOf(" ")))
                return pgData
            }
            return defaultDesc
        },
        formatedPara(para) {
            return para ? para.replace(/(?:\r\n|\r|\n)/g, '<br>') : '';
        }
    }
}
