
export default {
    scrollToTop: true,
    data() {
        return {
            phone: null,
            regNo: null,
            type: null,
            loading: false,
        };
    },
    mounted(){
        localStorage.removeItem('sellCarData');
    },
    methods: {
        // Validate and Submit form data to a add category
        sendDetails(btnType) {
            // Validate all fields for making the API call, throw error and not call API if validation errors are present
            this.$refs.sellmycarForm.validate().then(success => {
                if (!success) {
                } else {
                    
                    // If Valid form
                    // Create formData instance to pass the data to the API
                    let formData = {
                        reg: this.regNo,
                        phone: this.phone,
                        type: btnType,
                    }
                    // localStorage.setItem("sellCarData", JSON.stringify(formData));
                    // this.$router.push({
                    //     path: '/choose-plan-sell-car'
                    // })
                    // Add header for the API
                    const headers = {};
                    this.loading = true;
                    // AXIOS api call to add categories api
                    this.$axios.post('/api/visitors/addSellVehicleVisitor', formData, {
                        headers: headers
                    }).then(response => {
                    })
                        .finally(() => {
                            this.loading = false;
                        });
                        //link = https://motorway.co.uk/partner/checkmot?utm_source=checkmot&utm_medium=affiliate&utm_campaign=sellyourcaronline&vrm=${this.regNo}&vrm_redirect=true
                    let link = `https://track.webgains.com/click.html?wgcampaignid=1682460&wgprogramid=285665&clickref=Flow`;
                    window.open(link, "_blank");
                }
            });
        }
    }
}

